const hasFeature = (featureName) => {
  let userAbilities = document.querySelector('[data-user-plan]')?.dataset?.userAbilities
  if (!userAbilities) return false

  const result = JSON.parse(userAbilities).includes(featureName)
  Logger(`hasFeature ${featureName}`, result);

  return result;
}

export default hasFeature
